.bottomNavContainer {
  height: 5.5vh;
}

.overlay {
  background-color: black;
  background: radial-gradient(
    46.15% 106.11% at 62.67% 30.5%,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background-image: radial-gradient(
    46.15% 106.11% at 62.67% 30.5%,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}
